import React from 'react'
import styled from 'styled-components'
import Blog from '../../../../models/Blog'
import { HtmlDiv } from '../../../../utils/types'
import Article from '../../../Core/Content/Article/Article'
import Section from '../../../Core/Layout/Section/Section'
import Breadcrumbs from '../../../Core/Navigation/Breadcrumbs/Breadcrumbs'
import Layout from '../../Layout/Layout'
import BlogGutterLeft from './GutterLeft/BlogGutterLeft'
import BlogHeader from './Header/BlogHeader'

const BlogPage = ({ blog }: BlogPageProps) => {
  return (
    <Layout>
      <Section>
        <Breadcrumbs
          links={getBlogBreadcrumbLinks(blog)}
          className="mb-10 mt-10"
        />
        <div className="flex">
          {blog.relatedBlogs.length > 0 ||
            (blog.article.tableOfContents.items &&
              blog.article.tableOfContents.items.length > 0 && (
                <BlogGutterLeft blog={blog} />
              ))}
          <div className="flex-grow">
            <BlogHeader blog={blog} className="mb-3" />
            <Article article={blog.article} />
          </div>
        </div>
      </Section>
    </Layout>
  )
}

export const getBlogBreadcrumbLinks = (blog: Blog) => [
  {
    url: '/blog',
    label: 'blog',
  },
  {
    url: `/blog/${blog.slug}`,
    label: blog.title,
  },
]

export interface BlogPageProps extends HtmlDiv {
  blog: Blog
}

export default styled(BlogPage)``
