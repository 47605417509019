import React from 'react'
import styled from 'styled-components'
import Blog from '../../../../../models/Blog'
import { HtmlDiv } from '../../../../../utils/types'
import RelatedBlogs from '../RelatedBlogs/RelatedBlogs'
import BlogTableOfContents from '../TableOfContents/BlogTableOfContents'

const BlogGutterLeft = ({ blog, className, ...rest }: BlogGutterLeftProps) => {
  return (
    <div
      className={`relative hidden flex-shrink-0 md:block mr-4 w-xs ${className}`}
      {...rest}
    >
      <div className="sticky top-4">
        {blog.article.tableOfContents.items &&
          blog.article.tableOfContents.items.length > 0 && (
            <BlogTableOfContents blog={blog} />
          )}
        {blog.relatedBlogs.length > 0 && <RelatedBlogs blog={blog} />}
      </div>
    </div>
  )
}

export interface BlogGutterLeftProps extends HtmlDiv {
  blog: Blog
}

export default styled(BlogGutterLeft)``
