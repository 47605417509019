import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { HtmlDiv } from '../../../../utils/types'
import Link from '../Link/Link'

const NavSidebar = ({ links, className, ...rest }: NavSidebarProps) => {
  return (
    <div className={`flex flex-col ${className}`} {...rest}>
      {links.map((link) => (
        <Link
          key={link.text + link.url}
          to={link.url}
          className={`cursor-pointer relative ${
            link.isActive ? 'active-link' : 'inactive-link'
          }`}
        >
          {link.text}
        </Link>
      ))}
    </div>
  )
}

export interface NavSidebarProps extends HtmlDiv {
  links: Array<NavSidebarLink>
}

interface NavSidebarLink {
  url: string
  text: string
  isActive?: boolean
}

export default styled(observer(NavSidebar))`
  .inactive-link {
    left: ${(props) => (props.links.find((l) => l.isActive) ? '1rem' : '0rem')};
  }
  .active-link {
    ${tw`font-bold`}
    color: ${(props) => props.theme.text.colors.primary};
  }
`
