import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { HtmlDiv } from '../../../../utils/types'
import Icon from '../../Content/Icon/Icon'
import Link from '../Link/Link'

const Breadcrumbs = ({ links, className, ...rest }: BreadcrumbsProps) => {
  return (
    <nav className={`flex flex-wrap items-center ${className}`} {...rest}>
      <Link className="no-underline flex items-center" to="/">
        <Icon className="relative home-icon" name="home" />{' '}
        <div>&nbsp;/&nbsp;</div>
      </Link>
      {links.map((link, i) => (
        <Link key={link.url} className="no-underline" to={link.url}>
          <span className="underline">{link.label}</span>
          {i < links.length - 1 && <>&nbsp;/&nbsp;</>}
        </Link>
      ))}
    </nav>
  )
}

export interface BreadcrumbsLink {
  url: string
  label: string
}

export interface BreadcrumbsProps extends HtmlDiv {
  links: BreadcrumbsLink[]
}

export default styled(observer(Breadcrumbs))`
  .home-icon {
    bottom: 0.1rem;
  }
`
