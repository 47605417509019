import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import BlogAvatarImage from '../../BlogList/AvatarImage/BlogAvatarImage'
import Chip from '../../../../Core/Content/Chip/Chip'
import Blog from '../../../../../models/Blog'
import { HtmlDiv } from '../../../../../utils/types'

const BlogHeader = ({ blog, className, ...rest }: BlogHeaderProps) => {
  return (
    <div className={`relative ${className}`} {...rest}>
      <div
        className="absolute top-0 right-0 text-accent -z-10 opacity-25 mt-10"
        style={{ fontSize: '7rem' }}
      >
        Blog
      </div>
      <h1 className="mb-4 mt-0">{blog.title}</h1>
      <div className="flex items-center flex-wrap">
        <div className="flex flex-wrap">
          {blog.categories.map((category, i) => (
            <Chip
              key={category}
              className={`mr-1 mb-1 bg-primary text-light ${
                i === blog.categories.length - 1 && 'mr-6'
              }`}
            >
              {category}
            </Chip>
          ))}
        </div>
        <div className="flex flex-wrap items-center">
          <BlogAvatarImage
            src="https://avatars.githubusercontent.com/u/13106307?v=4"
            className="mr-3"
          />
          Mike Dane
        </div>
      </div>
    </div>
  )
}

export interface BlogHeaderProps extends HtmlDiv {
  blog: Blog
}

export default styled(observer(BlogHeader))``
