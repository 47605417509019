import React from 'react'
import { graphql } from 'gatsby'
import Blog, { BlogPageQuery } from '../models/Blog'
import { HtmlDiv } from '../utils/types'
import styled from 'styled-components'
import BlogPage from '../components/MarketingSite/Pages/Blog/BlogPage'

const BlogTemplate = ({ data }: BlogPageProps) => {
  const blog = Blog.fromBlogPageQuery(data.blog)
  return <BlogPage blog={blog} />
}

interface BlogPageProps extends HtmlDiv {
  data: { blog: BlogPageQuery }
}

export default styled(BlogTemplate)``

export const query = graphql`
  query BlogPageQuery($slug: String!) {
    blog(slug: { eq: $slug }) {
      title
      slug
      description
      categories
      body {
        body
        tableOfContents
        wordCount {
          words
        }
      }
      releaseDate
      relatedBlogs {
        title
        slug
      }
    }
  }
`
