import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import TableOfContents from '../../../../Core/Navigation/TableOfContents/TableOfContents'
import Blog from '../../../../../models/Blog'
import { HtmlDiv } from '../../../../../utils/types'

const BlogTableOfContents = ({
  blog,
  className,
  ...rest
}: BlogTableOfContentsProps) => {
  return (
    <div className={`${className}`} {...rest}>
      <h4>on this page</h4>
      <TableOfContents
        className="text-base"
        tableOfContents={blog.article.tableOfContents}
      />
    </div>
  )
}

export interface BlogTableOfContentsProps extends HtmlDiv {
  blog: Blog
}

export default styled(observer(BlogTableOfContents))`
  ul {
    margin-left: 0rem !important;
  }
`
