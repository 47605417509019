import React from 'react'
import styled from 'styled-components'
import Blog from '../../../../../models/Blog'
import { HtmlDiv } from '../../../../../utils/types'
import NavSidebar from '../../../../Core/Navigation/NavSidebar/NavSidebar'

const RelatedBlogs = ({ blog, className, ...rest }: RelatedBlogsProps) => {
  return (
    <div className={`${className}`} {...rest}>
      <h4>Related Blogs</h4>
      <NavSidebar
        className="text-base"
        links={blog.relatedBlogs.map((rb) => ({
          url: `/blogs/${rb.slug}`,
          text: rb.title,
        }))}
      />
    </div>
  )
}

export interface RelatedBlogsProps extends HtmlDiv {
  blog: Blog
}

export default styled(RelatedBlogs)``
